import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Graph } from "../../Components";
import "./index.css";
import {
  getDaysBetween2Dates,
  getGrandPrizeDrawsForKeyword,
  getPastMonths,
} from "../../helpers/utils";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import SearchKeyword from "../../Components/SearchKeyword";
import dayjs from "dayjs";
import Chart from "../Overall/chart";
import axios from "axios";

type Inventory = {
  campaignKey: string;
  giftCardTypeKey?: string;
  prizeKey?: string;
  prizePoolKey?: string;
  value: number;
  unassignedCount: number;
  assignedCount: number;
  prizeName: string;
  description: string;
  inventoryCount?: number;
};

type InventoryReturn = {
  instantWinsInventory: Inventory[];
  grandPrizeInventory: Inventory[];
};

interface PrizeReport extends MainDbReturnTypes.PrizePoolPrize {
  totalWon: number;
  claimed: number;
  forfeited: number;
}

function Overview() {
  const storeKeyword = useStores().keywordStore;
  const [keyword, setKeyword] = useState(storeKeyword.keyword);
  const navigate = useNavigate();
  const [selectedmonths, setSelectedmonths] = useState(12);
  const [storemonth, selectStoremonth] = useState(12);

  useEffect(() => {
    if (storeKeyword.keyword.word) {
      setKeyword(storeKeyword.keyword);
    } else {
      navigate("/keywordoverview");
    }
  }, [storeKeyword.keyword]);

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    ["getParticipants", { keyword }],
    async () => {
      const res: any = await campaignClient.call("getParticipants", {
        allTags: [`keyword:${keyword.keywordKey}`],
      });

      let participants;

      if (res.participants) {
        participants = res.participants;
      }
      if (res.result) {
        participants = res.result.participants;
      }
      if (res.largeResultUrl) {
        const result = await axios(res.largeResultUrl);

        const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
        participants = response;
      }
      return participants.filter((p: any) => p.firstName);;

    },
    { enabled: keyword.keywordKey ? true : false }
  );

  const { isLoading: isLoadingPrizeWinners, data: winners } = useQuery(
    ["getWinners", { keyword }],
    async () => {
      const grandPrizeDraws = await getGrandPrizeDrawsForKeyword(keyword.keywordKey);

      let winners: MainDbReturnTypes.PrizeWinner[] = [];

      for (let draw of grandPrizeDraws) {
        const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
          grandPrizeDrawKey: draw.grandPrizeDrawKey,
        });
        winners.push(...res.prizeWinners);
      }

      return winners;
    },
    { enabled: keyword.keywordKey ? true : false }
  );

  const { isLoading: isLoadingPools, data: prizePool } = useQuery(
    ["getPrizePools", { keyword }],
    async () => {
      const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});
      return res.prizePools.find((p) => p.prizePoolName === keyword.word);
    },
    { enabled: keyword.keywordKey ? true : false }
  );

  const { isLoading: isLoadingEntries, data: grandPrizeEntries } = useQuery(
    ["getParticipants1", { prizePool }],
    async () => {
      const res: any = await campaignClient.call(
        "getGrandPrizeEntries",
        {
          prizePoolKey: prizePool?.prizePoolKey,
        }
      );
      let entries;

      if (res.grandPrizeEntries) {
        entries = res.grandPrizeEntries;
      }
      if (res.result) {
        entries = res.result.grandPrizeEntries;
      }
      if (res.largeResultUrl) {
        const result = await axios(res.largeResultUrl);

        const response: MainDbReturnTypes.GrandPrizeEntry[] = await result.data.result.grandPrizeEntries;
        entries = response;
      }
      return entries;
    },
    { enabled: prizePool ? true : false }
  );
  const { isLoading: isLoadingLocations, data: keywordLocations } = useQuery(
    ["getKeywordLocations", { keyword }],
    async () => {
      const res: MainDbReturnTypes.KeywordLocations = await campaignClient.call(
        "getKeywordLocations",
        {
          keywordKey: keyword.keywordKey,
        }
      );
      return res.keywordLocations;
    },
    { enabled: keyword.keywordKey ? true : false }
  );
  const { isLoading: isLoadingPrizes, data: prizes } = useQuery(
    ["getPrizes", { keyword }],
    async () => {
      const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});
      const res1: MainDbReturnTypes.PrizePoolPrizes = await campaignClient.call(
        "getPrizePoolPrizes",
        {
          prizePoolKey: res.prizePools.find((p) => p.prizePoolName.includes(keyword.word))
            ?.prizePoolKey,
        }
      );
      const prizes = await Promise.all(
        res1.prizePoolPrizes.map(async (p) => {
          const result: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {
            prizeKey: p.prizeKey,
          });
          return result.prizes[0];
        })
      );

      return res1.prizePoolPrizes
        .filter((prize) => prize.active)
        .map((prize) => {
          return {
            ...prize,
            prizePoolName: res.prizePools.find((p) => p.prizePoolName.includes(keyword.word))
              ?.prizePoolName,
            prizeName: prizes.find((p) => p.prizeKey === prize.prizeKey)?.prizeName || "",
          };
        });
    },
    { enabled: keyword.keywordKey ? true : false }
  );

  const prizeStats = useMemo(() => {
    let prizesFinal: PrizeReport[] = [];

    if (winners && prizes) {
      prizes.length > 0 &&
        prizes.forEach((prize) => {
          const won = winners.length;

          const claimed = winners.filter(
            (v) => v.prizeKey === prize.prizeKey && v.declarationAndRelease
          );

          const forfeited = winners.filter((v) => v.prizeKey === prize.prizeKey && v.forfeitTime);

          prizesFinal.push({
            ...prize,
            totalWon: won,
            claimed: claimed.length,
            forfeited: forfeited.length,
          });
        });
    }

    return prizesFinal;
  }, [winners, prizes]);

  const stats = useMemo(() => {
    if (grandPrizeEntries) {
      return {
        total: grandPrizeEntries.length,
      };
    }
  }, [grandPrizeEntries]);

  const data = useMemo(() => {
    if (participants && participants.length > 0 && grandPrizeEntries) {
      return participants.map((participant: MainDbReturnTypes.Participant) => {
        const entry = grandPrizeEntries.find(
          (e: MainDbReturnTypes.GrandPrizeEntry) => e.participant.sessionKey === participant.sessionKey
        );

        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          creationTime: entry ? new Date(entry.creationTime) : "",
          updateTime: new Date(participant.updateTime),
          sessionKey: participant.sessionKey,
          // rules: participant.metadata.rules
          //   ? participant.metadata.rules.toLocaleString()
          //   : "NA",
          // optin1: participant.metadata.optin1
          //   ? participant.metadata.optin1.toLocaleString()
          //   : "NA",
          // age: participant.metadata.age
          //   ? participant.metadata.age.toLocaleString()
          //   : "NA",
        };
      });
    }
  }, [participants]);


  const graphLabels = useMemo(() => {
    if (grandPrizeEntries && selectedmonths) {

      if ([12, 6, 3].includes(+selectedmonths)) {
        const xlables = getPastMonths(+selectedmonths).reverse();
        const ylables = xlables.map(
          (m) => grandPrizeEntries.filter((p: MainDbReturnTypes.GrandPrizeEntry) => dayjs(p.creationTime).format("MMM YY") === m).length
        );
        return { x: xlables, y: ylables };
      } else {
        const endDate = dayjs().toISOString();
        let startDate = dayjs().subtract(7, "day").toISOString();
        if (selectedmonths === 1) {
          startDate = dayjs()
            .set("month", dayjs().get("month") - 1)
            .toISOString();
        }

        if (selectedmonths === 2) {
          startDate = dayjs().subtract(14, "day").toISOString();
        }
        const xlables = getDaysBetween2Dates(startDate, endDate).map((date) =>
          dayjs(date).format("MMM DD")
        );
        const ylables = xlables.map(
          (m) =>
            grandPrizeEntries.filter(
              (p: MainDbReturnTypes.GrandPrizeEntry) => dayjs(p.creationTime).format("MMM DD YYYY") === m + " " + dayjs().year()
            ).length
        );

        return { x: xlables, y: ylables };
      }
    }
  }, [selectedmonths, data]);

  const storewideData = useMemo(() => {
    if (participants && keywordLocations) {
      let storewideParticipants: { [key: string]: number } = {};
      participants.map((p: MainDbReturnTypes.Participant) => {
        let locations = p.tags
          .filter((t) => t.startsWith("location"))
          .map((p) => p.replace("location:", ""));

        for (let location of locations) {
          let locationName = keywordLocations.find((l) => l.keywordLocationKey === location);
          if (locationName) {
            if (storewideParticipants[locationName.name]) {
              ++storewideParticipants[locationName.name];
            } else {
              storewideParticipants[locationName.name] = 1;
            }
          }
        }
      });

      const keyValueArray = Object.entries(storewideParticipants);
      keyValueArray.sort((a, b) => b[1] - a[1]);
      const sortedObject = Object.fromEntries(keyValueArray);
      return sortedObject;
    }
  }, [participants, keywordLocations]);

  const { isLoading: isLoadingInventory, data: inventory } = useQuery("getInventory", async () => {
    const res: InventoryReturn = await campaignClient.call("getInventory", {});
    return res;
  });

  if (
    // !stats ||
    isLoadingParticipants ||
    // !data ||
    isLoadingPrizes ||
    // !prizeStats ||
    isLoadingPrizeWinners ||
    isLoadingInventory ||
    isLoadingLocations ||
    isLoadingEntries ||
    isLoadingPools
  ) {
    return <PageLoader />;
  }

  if (!keyword.keywordKey) {
    return <SearchKeyword />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 && stats ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Keyword Overview</h2>
        {/* 
        <div className="main__actions">
          <li>
            <CSVLink
              data={data ? data : ""}
              filename={`Campaign-Report-${new Date().toLocaleDateString()}`}
              asyncOnClick={true}
              target="_blank"
              className="btn btn--medium btn--mobile-small"
            >
              Download Report
            </CSVLink>
          </li>
        </div> */}
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <p>Total entries</p>
                <h1>{stats.total}</h1>
              </div>
            </li>

            {prizeStats &&
              prizeStats.map((v: any, i) => {
                return (
                  <li key={v.prizeKey}>
                    <div className="info-box">
                      <h4>{v.prizeName}</h4>

                      <p>{v.totalWon} prizes won</p>
                      <p>{v.claimed} prizes claimed</p>
                      <p>{v.totalWon - v.claimed} prizes unclaimed</p>
                      <p>{v.forfeited} prizes forfeited</p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <br />
        <div className="chart-area">
          <div className="chart">
            <div
              className="chart-head"
              style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>
                <strong>Participants by month</strong>
              </h3>
              <select
                className="form-control"
                style={{ width: "150px", float: "right" }}
                value={selectedmonths}
                onChange={(e) => {
                  setSelectedmonths(+e.currentTarget.value);
                }}>
                <option value="12">12 months</option>
                <option value="6">6 months</option>
                <option value="3">3 months</option>
                <option value="1">1 month</option>
                <option value="2">2 weeks</option>
                <option value="0">1 week</option>
              </select>
            </div>
            <div className="legend">
              <div
                className="check_box check_box_1"
                style={{
                  backgroundColor: "blue",
                  display: "inline-block",
                  marginRight: "10px",
                }}></div>
              Number of participants
            </div>

            <div className="graph">
              {graphLabels && <Graph graphLabels={graphLabels} selectedmonths={selectedmonths} />}
            </div>
          </div>
          <div className="chart">
            <div
              className="chart-head"
              style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>
                <strong>Store wide participation</strong>
              </h3>
            </div>

            {storewideData && <Chart participantsData={storewideData} legend={false} />}
          </div>
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no stats to display.</p>
  );
}

export default Overview;
